$mat-icon-vertical-align: ('middle', 'sub', 'text-bottom', 'text-top', 'top');

$mat-icon-sizes: (
  8: 8px,
  10: 10px,
  12: 12px,
  16: 16px,
  18: 18px,
  20: 20px,
  22: 22px,
  24: 24px,
  26: 26px,
  28: 28px,
  30: 30px,
  32: 32px,
  34: 34px,
  36: 36px,
  38: 38px,
  40: 40px,
);

.mat-icon {
  @each $vertical-align in $mat-icon-vertical-align {
    &.va-#{$vertical-align} {
      vertical-align: #{$vertical-align} !important;
    }
  }

  @each $size, $pixels in $mat-icon-sizes {
    &.mat-icon-#{$size} {
      height: #{$pixels};
      width: #{$pixels};

      &.material-icons {
        font-size: #{$pixels};
      }
    }
  }
}
