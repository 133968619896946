@use 'sass:map';
@use '@angular/material' as mat;

@mixin _qaroni-mat-button-theme-property($name, $theme, $property, $hue) {
  $color-config: mat.get-color-config($theme);

  $primary-palette: map.get($color-config, 'primary');
  $color: mat.get-color-from-palette($primary-palette, $hue);

  $background-palette: map.get($color-config, 'background');
  $background: mat.get-color-from-palette(
    $background-palette,
    'disabled-button'
  );

  $foreground-palette: map.get($color-config, 'foreground');
  $foreground: mat.get-color-from-palette(
    $foreground-palette,
    'disabled-button'
  );

  &.#{$name} {
    #{$property}: $color;
  }

  &.#{$name},
  &.mat-button-disabled {
    &.mat-button-disabled {
      $palette: if($property == 'color', $foreground, $background);
      #{$property}: $palette;
    }
  }
}

@mixin _qaroni-mat-button-focus-overlay-color($name, $theme) {
  $color-config: mat.get-color-config($theme);

  $palette: map.get($color-config, 'primary');
  $color: mat.get-color-from-palette($palette, 500);

  &.#{$name} .mdc-button-focus-overlay {
    background-color: $color;
  }

  &.mdc-button-disabled .mdc-button-focus-overlay {
    background-color: transparent;
  }
}

@mixin _qaroni-mat-button-ripple-background($theme, $hue, $opacity) {
  $color-config: mat.get-color-config($theme);

  $palette: map.get($color-config, 'primary');
  $background-color: mat.get-color-from-palette($palette, $hue, $opacity);

  background-color: $background-color;
  @if (type-of($background-color) != color) {
    opacity: $opacity;
  }
}

@mixin _qaroni-mat-button-ripple-color($name, $theme, $hue, $opacity: 0.2) {
  &.#{$name} .mat-ripple-element {
    @include _qaroni-mat-button-ripple-background($theme, $hue, $opacity);
  }
}

@mixin qaroni-build-mat-color-button($name, $color, $text: white) {
  $qaroni-primary: (
    100: $color,
    500: $color,
    700: $color,
    contrast: (
      100: $text,
      500: $text,
      700: $text,
    ),
  );

  $qaroni-palette: mat.define-palette($qaroni-primary);
  $theme: mat.define-light-theme(
    (
      color: (
        primary: $qaroni-palette,
        accent: $qaroni-palette,
      ),
    )
  );

  .mdc-button,
  .mdc-icon-button,
  .mdc-stroked-button {
    @include _qaroni-mat-button-theme-property($name, $theme, 'color', 'text');
    @include _qaroni-mat-button-focus-overlay-color($name, $theme);
  }

  .mdc-flat-button,
  .mdc-raised-button,
  .mdc-fab,
  .mdc-mini-fab {
    @include _qaroni-mat-button-theme-property(
      $name,
      $theme,
      'color',
      'default-contrast'
    );
    @include _qaroni-mat-button-theme-property(
      $name,
      $theme,
      'background-color',
      'default'
    );
    @include _qaroni-mat-button-ripple-color($name, $theme, 'default-contrast');
  }
}
