@use 'src/assets/scss/colors';

.qaroni-text-time {
  font-weight: 300;
  font-size: 12px;
}
.qaroni-text-bold {
  font-weight: 600;
}

.qaroni-text-semi-bold {
  font-weight: 500;
}

.qaroni-text-title {
  font-size: 14px !important;
}

.qaroni-text-num-ticket {
  font-size: 16px !important;
}

.qaroni-text-details {
  font-size: 11px !important;
}

.qaroni-text-menu {
  color: lighten(colors.$text, 70%);
}
