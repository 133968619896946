.cursor-pointer {
  cursor: pointer;
}

.lg-green {
  background-color: lightseagreen !important;
}

.lg-red {
  background-color: lightcoral !important;
}

.qaroni-min-w-52 {
  width: 52px;
}

.mat-mdc-menu-panel {
  overflow-x: hidden !important;
}

.mat-drawer.sidenav-branch {
  max-width: 350px !important;
  width: 350px;
}

.mdc-linear-progress__bar-inner {
  border-color: var(--qaroni-accent-900) !important;
}

.mdc-linear-progress__buffer-bar {
  background-color: var(--qaroni-accent-200) !important;
}
