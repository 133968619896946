@use 'src/assets/scss/mixins/buttons';
@use 'src/assets/scss/colors';

.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
  outline: none !important;
}

.mat-flat-button {
  padding: 0px 25px !important;
}

.mat-form-field-suffix .mat-icon {
  font-size: 100%;
}

// ==========================================================================================
// Colors
// ==========================================================================================

@include buttons.qaroni-build-mat-color-button(
  'mat-shop',
  colors.$qbono-one,
  colors.$qbono-one-contrast
);

@include buttons.qaroni-build-mat-color-button('mat-pink', #ff8fa4, #ffffff);

@include buttons.qaroni-build-mat-color-button(
  'mat-light-green',
  #86d5b0,
  #ffffff
);

@include buttons.qaroni-build-mat-color-button(
  'mat-light-blue',
  #8bb1d1,
  #ffffff
);
