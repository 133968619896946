@use 'src/assets/scss/colors';

table.mat-mdc-table.full-width-table {
  width: 100%;
}

table.mat-mdc-table {
  background-color: colors.$table-bg;

  th.mat-mdc-header-cell:first-of-type,
  td.mat-mdc-cell:first-of-type,
  td.mat-mdc-footer-cell:first-of-type {
    padding-right: 8px;
  }
  th.mat-mdc-header-cell,
  td.mat-mdc-cell,
  td.mat-mdc-footer-cell {
    padding-left: 8px;
    padding-right: 8px;
  }
  th.mat-mdc-header-cell:last-of-type,
  td.mat-mdc-cell:last-of-type,
  td.mat-mdc-footer-cell:last-of-type {
    padding-left: 8px;
  }
}

mat-paginator.mat-mdc-paginator {
  background-color: colors.$table-paginator;
}

.qaroni-widht {
  width: 100%;
}
