.style-close-dialog {
  position: relative !important;
}

.mat-mdc-dialog-container {
  padding: 35px 45px 50px 45px;
  border-radius: 2px;
}

.qaroni-close-icon.mat-mdc-button {
  float: right;
  z-index: 10;
  margin-top: 8px;
}
