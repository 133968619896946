/* You can add global styles to this file, and also import other style files */

@use 'assets/scss/theming/tokens';
@use 'assets/scss/theming/material-theme';
@use 'assets/scss/theming/colors-factory';

@use 'assets/scss/custom_class';
@use 'assets/scss/buttons';
@use 'assets/scss/cards';
@use 'assets/scss/circle_color';
@use 'assets/scss/colors';
@use 'assets/scss/comments';
@use 'assets/scss/dialogs';
@use 'assets/scss/drop_file';
@use 'assets/scss/fonts';
@use 'assets/scss/icons';
@use 'assets/scss/inputs';
@use 'assets/scss/links';
@use 'assets/scss/map_info';
@use 'assets/scss/snackbars';
@use 'assets/scss/tables';
@use 'assets/scss/typography';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Rubik';
}
