@use 'src/assets/scss/colors';
@use 'src/assets/scss/mixins/breakpoints';

%qaroni-card-base {
  border-radius: 1rem;

  box-shadow: none;

  background-color: colors.$card-bg;
  border: 2px solid colors.$card-border;
}

.mat-mdc-card.qaroni-card-one {
  @extend %qaroni-card-base;
}

.mat-mdc-card.qaroni-card-two {
  padding: 32px 24px 20px 24px;
  @extend %qaroni-card-base;

  .text-card-bottom {
    font-size: 1.125rem;
  }
}

.container-amount {
  font-weight: 400;
  font-size: 30px;
  line-height: 1.2;

  @include breakpoints.sm {
    font-size: 40px;
  }

  @include breakpoints.md {
    font-size: 30px;
  }

  @include breakpoints.md {
    font-size: 27.5px;
  }

  @include breakpoints.xl {
    font-size: 35px;
  }
}

.mat-mdc-card.card-bg-shop {
  background-color: lighten(colors.$qbono-one, 30%);
  border: 1px solid lighten(colors.$qbono-one, 25%);
}

.mat-mdc-card.card-bg-hostelry {
  background-color: lighten(colors.$qbono-two, 46%);
  border: 1px solid lighten(colors.$qbono-two, 41%);
}

.mat-card.card-bg-culture {
  background-color: lighten(colors.$qbono-three, 45%);
  border: 1px solid lighten(colors.$qbono-three, 40%);
}

.bg-full-card {
  background-color: colors.$amount-card-one;
  color: colors.$amount-card-one-contrast;
}

.bg-paid-card {
  background-color: colors.$amount-card-one !important;
  color: colors.$amount-card-one-contrast;
}

.bg-sale-card {
  background-color: colors.$amount-card-two;
  color: colors.$amount-card-two-contrast;
}

.bg-return-card {
  background-color: colors.$amount-card-two !important;
  color: colors.$amount-card-two-contrast;
}

.bg-add-card {
  background-color: colors.$amount-card-three;
  color: colors.$amount-card-three-contrast;
}

.bg-sent-card {
  background-color: colors.$amount-card-three !important;
  color: colors.$amount-card-three-contrast;
}

.bg-total-operations {
  background-color: #a2d1c8;
  color: colors.$amount-card-three-contrast;
}

.bg-pending-card {
  background-color: #999999 !important;
  color: white;
}

.bg-sent-card_dark {
  background-color: #84a3c8 !important;
  color: white;
}

.bg-paid-card_dark {
  background-color: #387b90 !important;
  color: white;
}

.bg-pending-card_dark {
  background-color: #a1a1a1 !important;
  color: white;
}

.bg-return-card_dark {
  background-color: #e5889c !important;
  color: white;
}

.content-mat-icon {
  width: 45px;
  height: 45px;
}

.sub-mat-icon {
  margin: auto;
  &.mat-icon-color {
    color: lighten(colors.$qbono-one, 30%);
  }
  &.mat-icon-send,
  &.mat-icon-liquidate {
    color: colors.$amount-card-three;
  }
  &.mat-icon-percentage,
  &.mat-icon-sale {
    color: colors.$amount-card-one;
  }
  &.mat-icon-payments {
    color: #999999;
  }
  &.mat-icon-currency,
  &.mat-icon-percentages {
    color: colors.$amount-card-two;
  }
  &.mat-icon-total {
    color: #a2d1c8;
  }
}

.card-ticket {
  background-color: colors.$theme-primary-700;
  width: 90px;
  height: 90px;
}
