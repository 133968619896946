@use 'src/assets/scss/colors';

.mdc-notched-outline__notch {
  border-right: none;
}

.mat-mdc-form-field {
  width: 100%;
}

.mat-mdc-form-field.ff-no-bottom-space {
  .mat-mdc-form-field-wrapper {
    margin-bottom: -1.25em;
  }
}

@supports (-moz-appearance: none) {
  .mat-mdc-form-field.moz-suffix {
    .mat-mdc-form-field-suffix {
      top: 0.6em;
    }
  }
}

.mat-mdc-checkbox {
  color: colors.$input-color;
  font-size: 13px;
  font-weight: 400;

  .mat-mdc-checkbox-layout {
    white-space: normal !important;

    .mat-mdc-checkbox-inner-container {
      margin-top: 4px !important;
    }
  }
}

.mat-mdc-select-panel {
  mat-option.mat-mdc-option {
    height: unset;
    color: colors.$input-color;
    font-size: 13.5px;
    line-height: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .mat-mdc-option-text {
    white-space: normal;
  }
}

.customer-inputs {
  height: 0px !important;
  padding: 0px;
}
.customer-inputs.mat-mdc-form-field-appearance-outline
  .mat-mdc-form-field-infix {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.customer-inputs.mat-mdc-form-field-appearance-outline
  .mat-mdc-form-field-wrapper {
  margin: 0px;
  padding: 0px;
}

.customer-inputs.mat-mdc-form-field-appearance-outline
  .mat-mdc-form-field-subscript-wrapper {
  display: none;
  padding: 0px;
}

.customer-inputs {
  .mat-mdc-text-field-wrapper {
    height: 36px;
    display: flex;
    align-items: center;
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-icon-suffix {
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.qaroni-customer .mat-mdc-mdc-text-field-wrapper {
  border: none !important;
  height: 25px !important;
  padding: 0px !important;
}

.qaroni-customer
  .mat-mdc-mdc-text-field-wrapper
  .mat-mdc-mdc-form-field-flex
  .mdc-notched-outline
  .mdc-notched-outline__leading {
  border: none;
}

.mat-mdc-form-field.qaroni-form-field-suffix-end {
  .mat-mdc-form-field-icon-suffix {
    align-self: end;
  }
}
