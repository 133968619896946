@font-face {
  font-family: 'Caceres Sans';
  src:
    url('/assets/fonts/CaceresSans/CaceresSans-Bold.otf') format('opentype'),
    url('/assets/fonts/CaceresSans/CaceresSans-Bold.ttf') format('truetype'),
    url('/assets/fonts/CaceresSans/CaceresSans-Bold.woff') format('woff'),
    url('/assets/fonts/CaceresSans/CaceresSans-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Caceres Sans';
  src:
    url('/assets/fonts/CaceresSans/CaceresSans-BoldItalic.otf')
      format('opentype'),
    url('/assets/fonts/CaceresSans/CaceresSans-BoldItalic.ttf')
      format('truetype'),
    url('/assets/fonts/CaceresSans/CaceresSans-BoldItalic.woff') format('woff'),
    url('/assets/fonts/CaceresSans/CaceresSans-BoldItalic.woff2')
      format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Caceres Sans';
  src:
    url('/assets/fonts/CaceresSans/CaceresSans-Italic.otf') format('opentype'),
    url('/assets/fonts/CaceresSans/CaceresSans-Italic.ttf') format('truetype'),
    url('/assets/fonts/CaceresSans/CaceresSans-Italic.woff') format('woff'),
    url('/assets/fonts/CaceresSans/CaceresSans-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Caceres Sans';
  src:
    url('/assets/fonts/CaceresSans/CaceresSans-Regular.otf') format('opentype'),
    url('/assets/fonts/CaceresSans/CaceresSans-Regular.ttf') format('truetype'),
    url('/assets/fonts/CaceresSans/CaceresSans-Regular.woff') format('woff'),
    url('/assets/fonts/CaceresSans/CaceresSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
