@use 'src/assets/scss/colors';

.upload {
  &__info {
    display: flex;
    justify-content: space-between;
  }
}

.progress-cont {
  height: 7px;
  width: 100%;
  border-radius: 4px;
  background-color: colors.$card-bg;
  position: relative;

  .progress {
    width: 0;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    border-radius: 4px;
    background-color: colors.$qbono-one;
    transition: 0.5s all;
  }
}

.button {
  display: inline-block;
  margin: 2px 4px;
}

.app-a {
  margin-right: 0;
}

.drop-zone {
  border: 2px dashed lighten(colors.$theme-accent-700, 30%);

  &__hovered {
    border: 2px solid colors.$theme-accent-700;
    .drop-zone__label {
      opacity: 0.3;
    }
  }
}

.file {
  &__label {
    color: lighten(colors.$theme-accent-700, 20%);
    &:hover {
      color: colors.$theme-accent-700;
      cursor: pointer;
    }
  }
}

.error {
  color: red;
  text-align: center;
  padding: 12px;
}
